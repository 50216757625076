<template>
  <div class="bg-gray-800">
    <div class="flex flex-wrap">
      <div class="w-full">
        <InputWrapper
          type="info"
          :label="'Setup how the viewer behaves for viewers'"
          :extra="'These settings change the default modes and behaviours available to non-editors'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import mixin from '../GuiSettingMixin'

export default {
  name: 'SettingsGeneralBlock',

  mixins: [mixin]

}
</script>
